var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "1" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "id",
                          label: _vm.$t("Id"),
                          type: "text-number",
                          placeholder: _vm.$t("Id"),
                        },
                        model: {
                          value: _vm.filter.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "id", $$v)
                          },
                          expression: "filter.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "document",
                          label: _vm.$t("CPF ou CNPJ"),
                          type: "text-mask",
                          mask: ["###.###.###-##", "##.###.###/####-##"],
                          placeholder: _vm.$t("CPF ou CNPJ"),
                        },
                        model: {
                          value: _vm.filter.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "document", $$v)
                          },
                          expression: "filter.document",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "name",
                          label: _vm.$t("Nome"),
                          placeholder: _vm.$t("Nome"),
                        },
                        model: {
                          value: _vm.filter.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "name", $$v)
                          },
                          expression: "filter.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "customer-classification",
                          label: _vm.$t("Classificação"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.customerClassificationsOptions,
                        },
                        model: {
                          value: _vm.filter.customerClassificationId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filter,
                              "customerClassificationId",
                              $$v
                            )
                          },
                          expression: "filter.customerClassificationId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "price-table",
                          label: _vm.$t("Tabela de preço"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.getComboPriceTables,
                        },
                        model: {
                          value: _vm.filter.priceTable,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "priceTable", $$v)
                          },
                          expression: "filter.priceTable",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          name: "status",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.activeInactiveTypes(),
                        },
                        model: {
                          value: _vm.filter.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "active", $$v)
                          },
                          expression: "filter.active",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "email",
                          type: "text",
                          label: _vm.$t("E-mail"),
                          placeholder: _vm.$t("nome@email.com"),
                        },
                        model: {
                          value: _vm.filter.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "email", $$v)
                          },
                          expression: "filter.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "person-type",
                          type: "vue-select",
                          options: _vm.personTypes(),
                          label: _vm.$t("Pessoa Física ou Jurídica ?"),
                          placeholder: _vm.$t("Todos"),
                          clearable: false,
                        },
                        model: {
                          value: _vm.filter.personType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "personType", $$v)
                          },
                          expression: "filter.personType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("e-address-province-combo", {
                        attrs: { id: "province", placeholder: _vm.$t("Todos") },
                        model: {
                          value: _vm.filter.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "province", $$v)
                          },
                          expression: "filter.province",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "city",
                          type: "text",
                          label: _vm.$t("Cidade"),
                          placeholder: _vm.$t("Cidade"),
                        },
                        model: {
                          value: _vm.filter.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "city", $$v)
                          },
                          expression: "filter.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "client-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.searched
                ? _vm.getEmptyTableMessage(_vm.$tc("CUSTOMER.NAME"))
                : _vm.$tc("tables.need_search"),
              fields: _vm.fields,
              items: _vm.clients,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(personType)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.getPersonType[row.item.personType]) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(telephones)",
                fn: function (row) {
                  return _vm._l(
                    row.item.telephones.map(function (t) {
                      return t.number
                    }),
                    function (number, index) {
                      return _c("div", { key: row.index + "_" + index }, [
                        _vm._v(" " + _vm._s(_vm._f("phone")(number)) + " "),
                        _c("br"),
                      ])
                    }
                  )
                },
              },
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.active,
                        "show-update":
                          !row.item.isStore && _vm.$can("Update", "Customer"),
                        "show-delete":
                          !row.item.isStore && _vm.$can("Delete", "Customer"),
                        "show-activate":
                          !row.item.isStore && _vm.$can("Activate", "Customer"),
                        "show-deactivate":
                          !row.item.isStore &&
                          _vm.$can("Deactivate", "Customer"),
                        "show-read-only": _vm.$can("Read", "Customer"),
                        buttons: [
                          {
                            event: "show-sale-history",
                            icon: "bag",
                            title: "Histórico de compras",
                          },
                        ],
                      },
                      on: {
                        update: function ($event) {
                          return _vm.showUpdateClientPage(row)
                        },
                        delete: function ($event) {
                          return _vm.deleteClient(row)
                        },
                        activate: function ($event) {
                          return _vm.onActivateDeactivateClient(row)
                        },
                        deactivate: function ($event) {
                          return _vm.onActivateDeactivateClient(row)
                        },
                        "read-only": function ($event) {
                          return _vm.showReadOnlyClientPage(row)
                        },
                        "show-sale-history": function ($event) {
                          return _vm.onShowSaleHistory(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "client-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Customer")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Cliente") },
            on: { click: _vm.showCreateClientPage },
          })
        : _vm._e(),
      _c("customer-sale-history-modal", { ref: "customerSaleHistoryModal" }),
      _c(
        "e-modal-custom-form",
        {
          ref: "modalActivateDeactivateCustomer",
          attrs: { title: _vm.modalActiveDeactiveTitle },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    staticClass: "w-100 required",
                    attrs: {
                      id: "modal_activate_deactivate-reason",
                      name: "reason",
                      type: "textarea",
                      validation: "required",
                      rows: "4",
                      label: _vm.$t("Motivo"),
                    },
                    model: {
                      value: _vm.activeDeactiveForm.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeDeactiveForm, "reason", $$v)
                      },
                      expression: "activeDeactiveForm.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }