var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-generic",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        size: "md",
        centered: "",
      },
      on: { hidden: _vm.onHideActions },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        { ref: "genericForm", attrs: { name: "genericForm" } },
        [
          _c(
            "b-container",
            [
              _vm._t("default"),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      staticStyle: { gap: "10px" },
                    },
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                          variant: "outline-primary",
                        },
                        on: { click: _vm.onHideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          busy: _vm.busy,
                          text: _vm.$t("Confirmar"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }