var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "customer_sale_modal",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "xl",
        scrollable: "",
        centered: "",
      },
      on: { shown: _vm.onShownModal, hidden: _vm.onHideActions },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.$t("Visualizar Cliente")) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "show-loading": _vm.loadingModal,
            "action-collapse": "",
            title: _vm.$t("Dados do cliente") + " #" + _vm.person.id,
          },
        },
        [
          _c("customer-form", {
            attrs: { "is-read-only": true, person: _vm.person },
          }),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: {
            "show-loading": _vm.loadingModal,
            "action-collapse": "",
            title: _vm.$t("Histórico de compras"),
          },
        },
        [_c("history-tab", { attrs: { "customer-id": _vm.person.id } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }