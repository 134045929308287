<template>
  <FormulateInput
    :id="$attrs.id || 'address-province'"
    v-model="content"
    :type="readOnly ? 'label' : 'vue-select'"
    :label="label"
    :placeholder="$attrs.placeholder || $t('Selecione')"
    :class="{ required: isRequired }"
    :options="provinces()"
    @input="handleInput"
    @blur="onBlur"
  />
</template>

<script>
import { province } from '@/mixins'

export default {
  mixins: [province],

  props: {
    value: {
      type: [String],
      default: null,
    },

    label: {
      type: String,
      default() {
        return this.$t('Estado')
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      content: null,
    }
  },

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    if (Array.isArray(this.value)) this.content = this.value
    else if (this.value) this.content = String(this.value)
  },

  methods: {
    handleInput() {
      this.$emit('input', this.content)
    },

    onBlur() {
      this.$emit('blur', this.content)
    },
  },
}
</script>

<style></style>
