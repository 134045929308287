<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="1">
            <FormulateInput
              id="id"
              v-model="filter.id"
              :label="$t('Id')"
              type="text-number"
              :placeholder="$t('Id')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="document"
              v-model="filter.document"
              :label="$t('CPF ou CNPJ')"
              type="text-mask"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              :placeholder="$t('CPF ou CNPJ')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="name"
              v-model="filter.name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="customer-classification"
              v-model="filter.customerClassificationId"
              :label="$t('Classificação')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="customerClassificationsOptions"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="price-table"
              v-model="filter.priceTable"
              :label="$t('Tabela de preço')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="getComboPriceTables"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filter.active"
              name="status"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="activeInactiveTypes()"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="email"
              v-model="filter.email"
              type="text"
              :label="$t('E-mail')"
              :placeholder="$t('nome@email.com')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="person-type"
              v-model="filter.personType"
              type="vue-select"
              :options="personTypes()"
              :label="$t('Pessoa Física ou Jurídica ?')"
              :placeholder="$t('Todos')"
              :clearable="false"
            />
          </b-col>
          <b-col md="2">
            <e-address-province-combo
              id="province"
              v-model="filter.province"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="city"
              v-model="filter.city"
              type="text"
              :label="$t('Cidade')"
              :placeholder="$t('Cidade')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="client-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="
          searched ? getEmptyTableMessage($tc('CUSTOMER.NAME')) : $tc('tables.need_search')
        "
        :fields="fields"
        :items="clients"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(personType)="row">
          {{ getPersonType[row.item.personType] }}
        </template>

        <template #cell(telephones)="row">
          <div
            v-for="(number, index) in row.item.telephones.map((t) => t.number)"
            :key="`${row.index}_${index}`"
          >
            {{ number | phone }}
            <br>
          </div>
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="!row.item.isStore && $can('Update', 'Customer')"
            :show-delete="!row.item.isStore && $can('Delete', 'Customer')"
            :show-activate="!row.item.isStore && $can('Activate', 'Customer')"
            :show-deactivate="!row.item.isStore && $can('Deactivate', 'Customer')"
            :show-read-only="$can('Read', 'Customer')"
            :buttons="[
              {
                event: 'show-sale-history',
                icon: 'bag',
                title: 'Histórico de compras',
              },
            ]"
            @update="showUpdateClientPage(row)"
            @delete="deleteClient(row)"
            @activate="onActivateDeactivateClient(row)"
            @deactivate="onActivateDeactivateClient(row)"
            @read-only="showReadOnlyClientPage(row)"
            @show-sale-history="onShowSaleHistory(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="client-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'Customer')"
      :main-tooltip="$t('Adicionar Cliente')"
      @click="showCreateClientPage"
    />

    <customer-sale-history-modal ref="customerSaleHistoryModal" />

    <e-modal-custom-form
      ref="modalActivateDeactivateCustomer"
      :title="modalActiveDeactiveTitle"
    >
      <b-row>
        <b-col
          md="12"
          class="d-flex flex-column"
        >
          <FormulateInput
            id="modal_activate_deactivate-reason"
            v-model="activeDeactiveForm.reason"
            name="reason"
            class="w-100 required"
            type="textarea"
            validation="required"
            rows="4"
            :label="$t('Motivo')"
          />
        </b-col>
      </b-row>
    </e-modal-custom-form>
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EStatusBadge } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { personTypes, statusTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EAddressProvinceCombo from '@/views/components/inputs/EAddressProvinceCombo.vue'
import EModalCustomForm from '@/views/components/EModalCustomForm.vue'
import CustomerSaleHistoryModal from './components/CustomerSaleHistoryModal.vue'

const getInitialActiveDeactiveForm = () => ({
  reason: '',
})

export default {
  name: 'ClientList',
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    EGridActions,
    EAddressProvinceCombo,
    CustomerSaleHistoryModal,
    EModalCustomForm,
  },

  mixins: [personTypes, statusTypes],

  data() {
    return {
      fetching: false,
      activeDeactiveForm: getInitialActiveDeactiveForm(),
      modalActiveDeactiveTitle: '',
    }
  },

  computed: {
    ...mapGetters('common/priceTables', ['getComboPriceTables']),
    ...mapGetters('pages/sale/clients', ['customerClassificationsOptions']),
    ...mapState('pages/sale/clients', {
      clients: 'clients',
      paging: 'paging',
      sorting: 'sorting',
      filter: 'filter',
      searched: 'searched',
    }),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: this.$t('Tipo'),
          key: 'personType',
          class: 'text-left',
          thClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Telefone'),
          key: 'telephones',
          class: 'text-left',
          thClass: 'text-center',
          thStyle: { width: '170px' },
        },
        {
          label: this.$t('Endereços'),
          key: 'addresses',
          class: 'text-left',
          thClass: 'text-center',
          thStyle: { width: '180px' },
          formatter: (value, index, item) =>
            item.addresses.map(ad => `${ad.city}-${ad.province}`).join(' / '),
        },
        {
          label: this.$t('Email'),
          key: 'email',
          class: 'text-left',
          thClass: 'text-center',
          thStyle: { width: '260px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thClass: 'text-center',
          thStyle: { width: '50px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.fetchPriceTables()
    this.fetchCustomerClassifications()
    this.getData()
  },

  methods: {
    ...mapActions('pages/sale/clients', [
      'fetchClients',
      'fetchCustomerClassifications',
      'setSearched',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'resetFilter',
    ]),
    ...mapActions('pages/sale/clients/clientMaintain', {
      stCleanClientMaintain: 'cleanState',
      stCleanSaleHistory: 'cleanSaleHistory',
    }),
    ...mapActions('common/priceTables', ['fetchPriceTables']),

    async getData() {
      this.$nextTick(async () => {
        try {
          // const hasFilter = Object.keys(this.filter).some(key => this.filter[key])
          // if (!hasFilter) {
          //   this.showWarning({ message: 'Use ao menos 1 filtro para pesquisar.' })
          //   return
          // }
          this.fetching = true
          await this.fetchClients()
          this.setSearched(true)
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetDeactivationForm() {
      this.activeDeactiveForm = getInitialActiveDeactiveForm()
    },

    resetFiltersLocal() {
      this.resetFilter()
      this.setSearched(false)
    },

    filterData() {
      this.setFilter(this.filter)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateClientPage() {
      this.stCleanClientMaintain()
      this.stCleanSaleHistory()
      this.$router.push({ name: 'client-add' })
    },

    async showUpdateClientPage(row) {
      if (this.isCustomerDeleted(row.item)) return

      const { id } = row.item

      this.$router.push({ name: 'client-maintain', params: { id } })
    },
    async showReadOnlyClientPage(row) {
      const { id } = row.item
      this.$router.push({ name: 'client-read-only', params: { id } })
    },

    async onShowSaleHistory(row) {
      await this.$refs.customerSaleHistoryModal.show(row.item.id)
    },

    async deleteClient(client) {
      if (this.isCustomerDeleted(client.item)) return

      const { id } = client.item
      try {
        const confirm = await this.confirm()
        if (confirm) {
          this.fetching = true
          await this.$http.delete(`/api/customers/${id}`)

          this.getData()
          this.showSuccess({ message: this.$t('Cliente removido com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async onActivateDeactivateClient(row) {
      if (this.isCustomerDeleted(row.item)) return

      try {
        this.modalActiveDeactiveTitle = row.item.active
          ? this.$t('Inativar Cliente')
          : this.$t('Ativar cliente')

        await this.$refs.modalActivateDeactivateCustomer.show(() =>
          this.updateActiveDeactive(row.item)
        )

        this.showSuccess({
          message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
        })
        this.getData()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.resetDeactivationForm()
      }
    },

    async updateActiveDeactive(item) {
      const { reason } = this.activeDeactiveForm
      let path = ''
      let data = {}

      if (item.active) {
        path = 'deactivate'
        data = { deactivationReason: reason }
      } else {
        path = 'activate'
        data = { activationReason: reason }
      }

      const confirm = await this.confirm(
        this.$t(`Confirmar ${item.active === true ? 'Inativação' : 'Ativação'}?`)
      )
      if (!confirm) throw new Error('Ação cancelada')

      await this.$http.put(`/api/customers/${path}`, {
        id: item.id,
        ...data,
      })
    },

    isCustomerDeleted(item) {
      if (item.deletedAt !== null) {
        this.showWarning({ message: this.$t('Não é possível alterar clientes excluídos') })
        return true
      }

      return false
    },
  },
}
</script>

<style></style>
