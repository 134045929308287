var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormulateInput", {
    class: { required: _vm.isRequired },
    attrs: {
      id: _vm.$attrs.id || "address-province",
      type: _vm.readOnly ? "label" : "vue-select",
      label: _vm.label,
      placeholder: _vm.$attrs.placeholder || _vm.$t("Selecione"),
      options: _vm.provinces(),
    },
    on: { input: _vm.handleInput, blur: _vm.onBlur },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v
      },
      expression: "content",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }