<template>
  <b-modal
    id="modal-generic"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideActions"
  >
    <template #modal-title>
      <p class="h4">
        {{ title }}
      </p>
    </template>

    <FormulateForm
      ref="genericForm"
      name="genericForm"
    >
      <b-container>
        <slot />

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="outline-primary"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [],

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      busy: false,
      modalResolve: null,
      modalReject: null,
      callback: async () => {},
    }
  },

  computed: {},

  methods: {
    show(callback) {
      this.callback = callback
      return new Promise((resolve, reject) => {
        this.$bvModal.show('modal-generic')
        this.modalReject = reject
        this.modalResolve = resolve
      })
    },

    async onConfirm() {
      try {
        this.busy = true
        this.$refs.genericForm.showErrors()
        if (this.$refs.genericForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        await this.callback()

        if (this.modalResolve) this.modalResolve()
        this.$emit('after-confirm')
        this.onHideModal()
      } catch (error) {
        if (error?.response) this.showError({ error })
        else this.showWarning({ message: error?.message || error })
      } finally {
        this.busy = false
      }
    },

    onHideModal() {
      this.callback = async () => {}
      this.$bvModal.hide('modal-generic')
    },

    onHideActions() {
      this.callback = async () => {}
      if (this.modalReject) this.modalReject('Ação cancelada')
    },
  },
}
</script>

<style lang="scss" scoped></style>
